<template>
    <div class="modal-select-reason">
        <div class="textbox">
            <div class="title f-24 p-t-40 m-l-20 m-r-20" v-if="options.status === 'rejected'">
                패스 사유가 어떻게 되시나요? <br />(복수선택 가능)
            </div>
            <div class="title f-24 p-t-40 m-l-20 m-r-20" v-else>어떤 점이 마음에 드셨나요? <br />(복수선택 가능)</div>
            <div class="description f-14 p-t-40 m-l-20 m-r-20">
                더 나은 매칭을 위해 매니저가 참고하는 정보이며,<br />
                상대방에게 절대 표시되지 않습니다.
            </div>
            <ul class="reason flex-fill p-t-40 p-l-30 p-r-20">
                <li
                    @click="onClickItem(item)"
                    class="item flex-row items-center flex-between"
                    :class="{ selected: item.$$selected }"
                    :key="item.id"
                    v-for="item in reasonItems"
                >
                    <div v-html="$translate(item.name)" />
                </li>
            </ul>
            <div v-if="options.status === 'rejected'" class="bottom-button">
                <button
                    class="btn-send-reason f-18"
                    :class="{ disabled: disabled }"
                    @click="onClickBtn(options.status)"
                    :disabled="disabled"
                >
                    패스하기
                </button>
            </div>
            <div v-else class="bottom-button">
                <button
                    class="btn-send-reason f-18"
                    :class="{ disabled: disabled }"
                    @click="onClickBtn(options.status)"
                    :disabled="disabled"
                >
                    수락사유 저장하기
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ModalSelectReason',
    props: ['options'],
    data: () => ({
        selectedValue: [],
        disabled: true,
        reasonItems: [
            {
                id: 0,
                name: '진정성',
                $$selected: false,
            },
            {
                id: 1,
                name: '외모',
                $$selected: false,
            },
            {
                id: 2,
                name: '키',
                $$selected: false,
            },
            {
                id: 3,
                name: '체형/몸매',
                $$selected: false,
            },
            {
                id: 4,
                name: '나이',
                $$selected: false,
            },
            {
                id: 5,
                name: '지역',
                $$selected: false,
            },
            {
                id: 6,
                name: '커리어 (직업/직장)',
                $$selected: false,
            },
            {
                id: 7,
                name: '학력',
                $$selected: false,
            },
            {
                id: 8,
                name: '퍼스널 스토리',
                $$selected: false,
            },
            {
                id: 9,
                name: '종교',
                $$selected: false,
            },
        ],
    }),
    watch: {
        selectedValue() {
            if (this.selectedValue.length > 0) {
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.disabled = true
                this.$emit('disabled', true)
            }
        },
    },
    methods: {
        onClickItem(item) {
            this.$set(item, '$$selected', !item.$$selected)
            setTimeout(() => {
                if (this.selectedValue && this.selectedValue.includes(item)) {
                    this.selectedValue = this.selectedValue.filter(i => i !== item)

                    if (this.selectedValue.length === 0) {
                        this.disabled = true
                        this.$emit('disabled', true)
                    }
                } else {
                    this.selectedValue.push(item)
                }
                this.selectedValue.sort((a, b) => a.id - b.id)
                // this.$emit('disabled', false)
                // this.reasonItems.forEach(o => this.$set(o, '$$selected', false))
            }, 200)
            this.searchValue = ''
        },
        async onClickBtn(status) {
            try {
                if (status === 'rejected') {
                    const idx = await this.$modal.basic({
                        title: '패스하시겠어요?',
                        body: '한 번 패스한 프로필은 다시 되돌릴 수 없습니다.<br>신중하게 결정해주세요.',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (idx === 1) {
                        const chat = this.$store.getters.chat
                        const reasons = []
                        for (let i = 0; i < this.selectedValue.length; i++) {
                            reasons.push(this.selectedValue[i].name)
                        }

                        const payload = {
                            accept_status: status,
                            reasons: reasons.join(', '),
                        }
                        await chatService.acceptProfile(chat.id, this.options.profilePhotoMessage.id, payload)
                        const message = chat.$$messages.find(
                            message => message.id === this.options.profilePhotoMessage.id
                        )
                        const content = this.$mustParse(message.content)

                        content.accept_status = status
                        message.content = JSON.stringify(content)

                        this.$emit('close', 'savedReasons')
                        this.$toast.success('패스 처리 되었습니다.')
                        this.$stackRouter.pop()
                    }
                } else {
                    const reasons = []
                    for (let i = 0; i < this.selectedValue.length; i++) {
                        reasons.push(this.selectedValue[i].name)
                    }
                    console.log(reasons)
                    const chat = this.$store.getters.chat
                    const payload = {
                        reasons: reasons.join(', '),
                    }

                    await chatService.updateAcceptReason(chat.id, this.options.profilePhotoMessage.id, payload)
                    this.$toast.success('수락 사유가 저장되었습니다.')

                    this.$emit('close', 'savedReasons')
                }
            } catch (e) {
                this.$toast.error(e.data)
                // console.log(e)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.modal-select-reason {
    // @include center;
    position: relative;
    .title {
        font-weight: 600;
    }
    .description {
        color: #696969;
        line-height: 19.2px;
    }
    ul {
        margin-bottom: 100px;

        li {
            @include center;
            padding: 17px;
            color: #888888;
            size: 14px;
            background: white;
            margin-bottom: 8px;
            border-radius: 8px;
            border: 1px solid #efdfd2;
            font-weight: 400;

            &.selected {
                color: #151360;
                border: 1px solid #151360;
                font-weight: 600;
            }
        }
    }
    .bottom-button {
        padding: 12px 20px 20px 20px;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff9ef;
        .btn-send-reason {
            height: 48px;
            width: 100%;
            border: none;
            border-radius: 36px;
            background: #151360;
            color: white;

            &.disabled {
                background: #d7d5d1;
            }
        }
    }
}
</style>
